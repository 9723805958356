import { IoCardOutline } from "react-icons/io5";
import { RiShipLine } from "react-icons/ri";
import { RiShieldCheckLine } from "react-icons/ri";
import { HiOutlineCalendar } from "react-icons/hi";

const Highlists = [
	{
		title: "Trusted Sellers",
		text: "All sellers are verified",
		icon: <RiShieldCheckLine />,
	},
	{
		title: "Secure Payment",
		text: "Pay without worry",
		icon: <IoCardOutline />,
	},
	{
		title: "Priority Delivery",
		text: "40 miles quick delivery",
		icon: <RiShipLine />,
	},
	{
		title: "Tool Rental",
		text: "Earn with us as a buyer",
		icon: <HiOutlineCalendar />,
	},
];

const IndexFeatures = () => (
	<div className=" home- bg-light my-3 mb-4 rounded ">
		<div className="   py-3">
			<div className="row">
				{Highlists.map((x) => (
					<div className="col d-md-block d-none">
						<div className="d-flex align-items-center justify-content-center">
							<div className="me-3 fs-3 text-blue">{x.icon}</div>
							<div>
								<p className="mb-0 font-lg fw-600">{x.title}</p>
								<span className="font-sm">{x.text}</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
);

export default IndexFeatures;
